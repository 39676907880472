import React from 'react';

const FormBody = () => {
  return (
    <>
      <p hidden>
        <label>
          Don’t fill this out: <input name="bot-field" />
        </label>
      </p>
      <p>
        <input
          type="text"
          name="name"
          className="w-100 input-reset pa3"
          placeholder="Full Name"
          required="true"
        />
      </p>
      <p>
        <input
          type="email"
          name="email"
          className="w-100 input-reset pa3"
          placeholder="Email Address"
          required="true"
        />
      </p>
      <p className="select-wrapper relative">
        <select className="w-100 pa3 input-reset bg-white">
          <option disabled selected value>
            Select Category
          </option>
          <option value="General Enquiry">General Enquiry</option>
          <option value="Commercial">Commercial</option>
          <option value="Retail">Retail</option>
          <option value="Apiculture">Apiculture</option>
          <option value="Organic">Organic</option>
        </select>
      </p>
      <p>
        <textarea name="message" className="w-100 input-reset pa3 h4" placeholder="Your Message" />
      </p>
    </>
  );
};

export default FormBody;
