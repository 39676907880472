import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import BioCard from '../components/BioCard';
import Fade from 'react-reveal/Fade';

class StaffBios extends React.Component {
  constructor({props}) {
    super(props);
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allPrismicStaffBio(sort: {fields: [data___page_weight], order: ASC}) {
              edges {
                node {
                  data {
                    name {
                      text
                    }
                    position {
                      text
                    }
                    portal_text {
                      text
                    }
                    bio {
                      html
                    }
                    portal_image {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 800, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                    bio_image {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 800, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                    email {
                      url
                    }
                    page_weight
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <Fade>
            <div className="modal-grid center">
              <div className="center relative mt4 mb4 mt6-l mb6-l flex-l justify-between flex-wrap-l">
                {data.allPrismicStaffBio.edges.map(bioItem => (
                  <BioCard input={bioItem.node} />
                ))}
              </div>
            </div>
          </Fade>
        )}
      />
    );
  }
}

export default StaffBios;
