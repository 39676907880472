import React, {Component} from 'react';
import PropTypes from 'prop-types';
import IntroText from './IntroText';
import ImageText from './ImageText';
import PageNav from './PageNav';
import BasicGallery from './BasicGallery';
import TwoColText from './TwoColText';
import ContactBlock from './ContactBlock';
import StaffBios from './StaffBios';
import ContactForm from './ContactForm';

export default class SliceZone extends Component {
  render() {
    const {allSlices} = this.props;
    const PageNavItems = allSlices.filter(function(item) {
      if (item.slice_type !== 'page_nav' && item.primary) {
        return item.primary.include_in_page_nav === 'on';
      }
      return null;
    });
    const slice = allSlices.map(s => {
      switch (s.slice_type) {
        // These are the API IDs of the slices
        case 'page_nav':
          return <PageNav key={s.id} items={PageNavItems} />;
        case 'intro_text':
          return <IntroText key={s.id} input={s} />;
        case 'image___text':
          return <ImageText key={s.id} input={s} />;
        case 'image_gallery':
          return <BasicGallery key={s.id} input={s} />;
        case 'two_column_text':
          return <TwoColText key={s.id} input={s} />;
        case 'contact_block':
          return <ContactBlock key={s.id} input={s} />;
        case 'staff_bios':
          return <StaffBios key={s.id} input={s} />;
        case 'contact_form':
          return <ContactForm key={s.id} input={s} />;
        default:
          return null;
      }
    });
    return <div className="slice-zone">{slice}</div>;
  }
}

SliceZone.propTypes = {
  allSlices: PropTypes.array.isRequired
};
