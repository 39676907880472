import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/layout';
import PageBanner from '../components/PageBanner';
import SliceZone from '../slices/SliceZone';
import ModalForm from '../components/ModalForm';

const Service = ({data: {prismicService}, location}) => {
  const {data, uid} = prismicService;
  return (
    <Layout customSEO>
      <SEO title={`${data.service_name.text}`} />
      <PageBanner bannerImage={data.page_banner} pageTitle={data.service_name.text} />
      {data.body && <SliceZone allSlices={data.body} />}
      <ModalForm pageTitle={data.service_name.text} formId={uid} />
    </Layout>
  );
};

export default Service;

Service.propTypes = {
  location: PropTypes.object.isRequired
};

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    prismicService(uid: {eq: $uid}) {
      uid
      data {
        service_name {
          text
        }
        page_banner {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1800, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        body {
          ... on PrismicServiceBodyIntroText {
            slice_type
            id
            primary {
              title {
                text
              }
              quote {
                text
              }
              text {
                html
              }
              page_nav_title {
                text
              }
              include_in_page_nav
            }
          }
          ... on PrismicServiceBodyImageText {
            slice_type
            id
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 900, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              title {
                text
              }
              text {
                html
              }
              link_text {
                text
              }
              link {
                link_type
                slug
                url
              }
              page_nav_title {
                text
              }
              include_in_page_nav
              image_position
              full_width
            }
          }
          ... on PrismicServiceBodyPageNav {
            slice_type
            id
          }
          ... on PrismicServiceBodyImageGallery {
            slice_type
            id
            primary {
              include_in_page_nav
              page_nav_title {
                text
              }
            }
            items {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              image_caption {
                text
              }
            }
          }
          ... on PrismicServiceBodyTwoColumnText {
            slice_type
            id
            primary {
              include_in_page_nav
              page_nav_title {
                text
              }
              text {
                html
              }
            }
          }
        }
      }
    }
  }
`;
