import React, { useState } from 'react';
import Img from 'gatsby-image';
import Modal from 'react-responsive-modal';
import BioModal from './BioModal';

const BioCard = ({ input }) => {
  //Hooks
  const [open, toggle] = useState(false);
  //--
  const modalstyles = {
    modal: {
      padding: '0px',
      width: '100%'
    }
  };
  return (
    <div className="bio-card tc pointer w-100 w-third-l pb5">
      <div onClick={() => toggle(true)}>
        <div className="db relative bg-tint-yellow">
          <Img fluid={input.data.portal_image.localFile.childImageSharp.fluid} />
          <div className="absolute w-100 h-100 top-0 left-0 flex items-center db z-max o-0 teaser">
            <div className="w-100 tc pl5 pr5 z-max">
              <div className="f3 measure lh-copy">{input.data.portal_text.text}</div>
              <div className="black button semi-bold ttu f6 tracked flex items-center w-100 justify-center mt4">
                Read More
              </div>
            </div>
          </div>
        </div>
        <div className="f3 semi-bold mt4 mb3">{input.data.name.text}</div>
        <div className="f6 semi-bold ttu yellow">{input.data.position.text}</div>
      </div>
      <Modal open={open} onClose={() => toggle(false)} center styles={modalstyles}>
        <BioModal input={input} />
      </Modal>
    </div>
  );
};

export default BioCard;
